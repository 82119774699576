import React from "react"
import Obfuscate from "react-obfuscate"
import { FaPhone, FaEnvelope } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactEn = ({ location }) => (
  <Layout lang="en" location={location}>
    <SEO
      title="Contact me"
      description="Contact Ludovic Guyot - Lacuzon Loisirs"
      lang="en"
    />
    <div className="has-text-centered mt-6">
      <div style={{ color: "#000", fontSize: 30 }}>
        <FaPhone /> 06 88 59 92 29
      </div>
      <div style={{ color: "#000", fontSize: 30 }}>
        <FaEnvelope />{" "}
        <Obfuscate
          style={{ color: "#000" }}
          email="contact@lacuzonloisirs.fr"
        />
      </div>
    </div>
    <form
      action="/post.php"
      method="post"
      style={{ width: 600, margin: "40px auto" }}
    >
      <div class="field">
        <label class="label" htmlFor="name">
          Your name
        </label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Name"
            name="name"
            id="name"
            required
            maxLength="254"
          />
        </div>
      </div>
      <div class="field">
        <label class="label" htmlFor="email">
          Your email
        </label>
        <div class="control">
          <input
            class="input"
            type="email"
            placeholder="Email"
            name="email"
            id="email"
            required
            maxLength="254"
          />
        </div>
      </div>
      <div class="field">
        <label class="label" htmlFor="subject">
          Subject
        </label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Subject"
            name="subject"
            id="subject"
            required
            maxLength="254"
          />
        </div>
      </div>
      <div class="field">
        <label class="label" htmlFor="message">
          Message
        </label>
        <div class="control">
          <textarea
            id="message"
            name="message"
            required
            style={{ width: "100%" }}
            rows="9"
            maxLength="10000"
          ></textarea>
        </div>
      </div>
      <div class="field">
        <label class="label" htmlFor="robot">
          What are the 3 first letters of the word Lacuzon ?
        </label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="3 first letters"
            name="robot"
            id="robot"
            required
            maxLength="254"
          />
        </div>
      </div>
      <button type="submit" className="button">
        Send message
      </button>
    </form>
  </Layout>
)

export default ContactEn
